import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import giftImage from "../images/gift_card.svg"

const ThemeRelatedThemes = props => {
    const { themeSlug } = props

    const data = useStaticQuery(graphql`
        query RelatedThemes {
            wpgraphql {
                qlThemes {
                    nodes {
                        id
                        title
                        slug
                        featuredImage {
                            sourceUrl
                            mediaItemId
                            modified
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 785) {
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    let themes = data.wpgraphql.qlThemes.nodes

    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex -= 1

            // And swap it with the current element.
            temporaryValue = array[currentIndex]
            array[currentIndex] = array[randomIndex]
            array[randomIndex] = temporaryValue
        }

        return array
    }

    themes = shuffle(themes)

    // Avoid current theme to be displayed
    let renderThemes = []
    themes.forEach(element => {
        if (themeSlug !== element.slug) {
            renderThemes.push(element)
        }
    })

    if (renderThemes.length > 0) {
        return (
            <section className="related-themes">
                <div className="section-header">
                    <span className="section-icon">
                        <img src={giftImage} alt="More Themes" />
                    </span>
                    <h3 className="section-title">
                        More Themes from Quema Labs
                    </h3>
                </div>

                <div className="related-themes-wrap">
                    {renderThemes.map((element, index) =>
                        (() => {
                            if (themeSlug !== element.slug && index < 3) {
                                return (
                                    <Link
                                        to={`/theme/${element.slug}/`}
                                        className="related-theme"
                                        key={`demo-` + index}
                                    >
                                        {element.featuredImage.imageFile !==
                                            null &&
                                            Object.keys(
                                                element.featuredImage.imageFile
                                            ).indexOf("childImageSharp") !==
                                                -1 && (
                                                <Img
                                                    fluid={
                                                        element.featuredImage
                                                            .imageFile
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                    alt={element.title}
                                                />
                                            )}
                                        <h5 className="related-theme-title">
                                            {element.title}
                                        </h5>
                                    </Link>
                                )
                            }
                        })()
                    )}
                </div>
            </section>
        )
    } else {
        return ""
    }
}

export default ThemeRelatedThemes
