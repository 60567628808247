import React from "react"
import { Link } from "gatsby"

const TableComparison = props => {
    const {
        features,
        pricePro = 59,
        priceFree = 0,
        textFree = "Download",
        textPro = "Buy Now",
        urlFree = "",
        urlPro = "",
    } = props

    if (!(features instanceof Array)) {
        return ""
    }

    return (
        <div className="feature-comparison-table">
            <div className="feature-comparison-table-desc feature-comparison-table-column">
                <ul className="feature-items">
                    {features.map((element, index) => (
                        <li key={`feature-` + index}>{element.feature}</li>
                    ))}
                </ul>
            </div>
            <div className="feature-comparison-table-free feature-comparison-table-column">
                <div className="feature-comparison-table-header">
                    <h5>Free</h5>
                    <span className="quema-labs-price table-header-price">
                        <span>$</span>
                        {priceFree}
                    </span>
                    <div className="table-header-waves"></div>
                </div>

                <ul className="feature-items">
                    {features.map((element, index) => {
                        if ("1" === element.free) {
                            return (
                                <li className="item-yes" key={`free-` + index}>
                                    Yes
                                </li>
                            )
                        } else {
                            return (
                                <li className="item-no" key={`free-` + index}>
                                    No
                                </li>
                            )
                        }
                    })}
                </ul>

                {(() => {
                    if (urlFree.includes("http")) {
                        return (
                            <a
                                href={urlFree}
                                className="table-button secondary-button"
                            >
                                {textFree}
                            </a>
                        )
                    } else {
                        return (
                            <Link
                                to={`/theme/` + urlFree}
                                className="table-button secondary-button"
                            >
                                {textFree}
                            </Link>
                        )
                    }
                })()}
            </div>
            <div className="feature-comparison-table-pro feature-comparison-table-column">
                <div className="feature-comparison-table-header">
                    <h5>Pro</h5>
                    {pricePro && (
                        <span className="quema-labs-price table-header-price">
                            <span>$</span>
                            {pricePro}
                        </span>
                    )}
                    <div className="table-header-waves"></div>
                </div>

                <ul className="feature-items">
                    {features.map((element, index) => {
                        if ("1" === element.premium) {
                            return (
                                <li
                                    className="item-yes"
                                    key={`premium-` + index}
                                >
                                    Yes
                                </li>
                            )
                        } else {
                            return (
                                <li
                                    className="item-no"
                                    key={`premium-` + index}
                                >
                                    No
                                </li>
                            )
                        }
                    })}
                </ul>

                {(() => {
                    if (urlPro.includes("http")) {
                        return (
                            <a
                                href={urlPro}
                                className="table-button secondary-hero-button"
                            >
                                {textPro}
                            </a>
                        )
                    } else {
                        return (
                            <Link
                                to={`/theme/` + urlPro}
                                className="table-button secondary-hero-button"
                            >
                                {textPro}
                            </Link>
                        )
                    }
                })()}
            </div>
        </div>
    )
}

export default TableComparison
