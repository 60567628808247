import React from "react"
import TableComparison from "./TableComparison"
import shoppingImage from "../images/shopping_basket_star.svg"

const ThemeFeatureComparison = props => {
    const {
        features,
        pricePro = 59,
        priceFree = 0,
        urlFree = "",
        urlPro = "",
        textFree = "Download",
        textPro = "Buy Now",
    } = props

    return (
        <section className="feature-comparison">
            <div className="feature-comparison-content">
                <div className="section-header">
                    <span className="section-icon">
                        <img src={shoppingImage} alt="Features Comparison" />
                    </span>
                    <h3 className="section-title">Features Comparison</h3>
                    <p className="section-text">
                        Use the free version or make much more with our premium
                        version to enable a lot of exciting features
                    </p>
                </div>
            </div>

            <div className="feature-comparison-table-wrap">
                <TableComparison
                    features={features}
                    pricePro={pricePro}
                    priceFree={priceFree}
                    urlFree={urlFree}
                    urlPro={urlPro}
                    textFree={textFree}
                    textPro={textPro}
                />
            </div>
        </section>
    )
}

export default ThemeFeatureComparison
