import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ThemesInclude from "../../components/ThemesInclude"
import ThemeTestimonials from "../../components/ThemeTestimonials"
import ThemeServices from "../../components/ThemeServices"
import ThemePurchaseInclude from "../../components/ThemePurchaseInclude"
import ThemeFeatureComparison from "../../components/ThemeFeatureComparison"
import ThemeRelatedThemes from "../../components/ThemeRelatedThemes"

import changelogImage from "../../images/rich_text_converter.svg" // Tell Webpack this JS file uses this image
import wordpressImage from "../../images/wordpress.svg"

import Img from "gatsby-image"

const SingleTheme = props => {
    const dataSite = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    wpUrl
                }
            }
        }
    `)
    const wpUrl = dataSite.site.siteMetadata.wpUrl

    const {
        pageContext: {
            title,
            subtitle,
            content,
            featuredImage,
            slug,
            services,
            price,
            version,
            eddId,
            downloadUrl,
            buyUrl,
            tableComparison,
            freeUrl,
            proUrl,
            demos,
        },
    } = props

    const arrayServices = services.split(",")

    let objTableComparison = []
    // Table comparison object
    if (tableComparison) {
        objTableComparison = JSON.parse(tableComparison)
        objTableComparison = objTableComparison[0]
    }

    let objDemos = []
    // Demos
    if (demos) {
        objDemos = JSON.parse(demos)
        objDemos = objDemos[0]
    }

    return (
        <Layout cssClasses="single-theme">
            <SEO
                title={title}
                description={subtitle}
                image={
                    featuredImage.imageFile !== null
                        ? props.location.origin +
                          featuredImage.imageFile.childImageSharp.fluid.src
                        : ""
                }
            />
            <section className="single-theme-main">
                <div className="single-theme-image-wrap">
                    <a
                        className="single-theme-image-link"
                        href={`https://demo.quemalabs.com/${slug}/`}
                        aria-label={title}
                    >
                        {featuredImage.imageFile !== null &&
                            Object.keys(featuredImage.imageFile).indexOf(
                                "childImageSharp"
                            ) !== -1 && (
                                <Img
                                    fluid={
                                        featuredImage.imageFile.childImageSharp
                                            .fluid
                                    }
                                    alt={title}
                                    className="single-theme-image"
                                />
                            )}
                    </a>
                    <div className="single-theme-preview-wrap">
                        <a
                            href={`https://demo.quemalabs.com/${slug}/`}
                            className="single-theme-preview-button secondary-color-button"
                        >
                            Preview
                        </a>
                    </div>
                </div>

                <div className="single-theme-info">
                    <h1 className="single-theme-title">{title}</h1>
                    <p className="single-theme-sub-title">{subtitle}</p>

                    <div className="single-theme-price-wrap">
                        {price > 0 && (
                            <span className="single-theme-price quema-labs-price">
                                <span>$</span>
                                {price}
                            </span>
                        )}

                        {(() => {
                            if (downloadUrl) {
                                return (
                                    <a
                                        href={downloadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-title={title}
                                        className="single-theme-download-button hero-button"
                                    >
                                        Download
                                    </a>
                                )
                            } else if (buyUrl) {
                                return (
                                    <a
                                        href={`${buyUrl}?ref=QuemaLabs`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="single-theme-buy-button hero-button"
                                        data-title={title}
                                        data-type="Buy"
                                    >
                                        Buy Now
                                    </a>
                                )
                            } else {
                                return (
                                    <a
                                        href={`${wpUrl}checkout?edd_action=add_to_cart&download_id=${eddId}`}
                                        data-title={title}
                                        className="single-theme-buy-button hero-button"
                                    >
                                        Buy Now
                                    </a>
                                )
                            }
                        })()}
                    </div>

                    <div className="single-theme-price-footer">
                        {version && (
                            <div className="single-theme-footer-changlog">
                                <a href="https://www.quemalabs.com/changelog">
                                    <img src={changelogImage} alt="Changelog" />
                                    Version: {version}
                                </a>
                            </div>
                        )}

                        <div className="single-theme-footer-platform">
                            <img src={wordpressImage} alt="WordPress" />
                            WordPress
                        </div>
                    </div>
                </div>
            </section>

            <ThemeServices services={arrayServices} />

            {objDemos instanceof Array && objDemos.length > 1 && (
                <section className="demos-preview-wrap">
                    <h4>Demos</h4>
                    <div className="demos-preview">
                        {objDemos.map((element, index) => (
                            <a
                                href={element.demoUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="demo-preview"
                                key={`demo-` + index}
                            >
                                <img
                                    src={element.demoImage}
                                    alt={element.demoName}
                                />
                            </a>
                        ))}
                    </div>
                </section>
            )}

            <div
                className="entry-content"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>

            {objTableComparison instanceof Array &&
                objTableComparison.length > 1 && (
                    <ThemeFeatureComparison
                        features={objTableComparison}
                        pricePro={price > 0 ? price : ""}
                        urlFree={freeUrl}
                        urlPro={
                            eddId
                                ? `${wpUrl}checkout?edd_action=add_to_cart&download_id=${eddId}`
                                : proUrl
                        }
                    />
                )}

            <ThemeTestimonials themeSlug={slug} />

            {price > 0 && (
                <ThemePurchaseInclude
                    themeSlug={slug}
                    themeName={title}
                    themePrice={price}
                    buyUrl={`${wpUrl}checkout?edd_action=add_to_cart&download_id=${eddId}`}
                />
            )}

            <ThemeRelatedThemes themeSlug={slug} />

            <ThemesInclude />
        </Layout>
    )
}

export default SingleTheme
