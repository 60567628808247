import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import quoteImage from "../images/quote.svg"

const ThemeTestimonials = props => {
    const { themeSlug } = props

    const data = useStaticQuery(graphql`
        query Testimonials {
            wpgraphql {
                qlTestimonialCategories {
                    edges {
                        node {
                            testimonials {
                                edges {
                                    node {
                                        title
                                        content
                                    }
                                }
                            }
                            slug
                        }
                    }
                }
            }
        }
    `)

    const testimonialCategory = data.wpgraphql.qlTestimonialCategories.edges

    if (!(testimonialCategory instanceof Object)) {
        return ""
    }

    let testimonials = []

    // Let's see if we have testimonial for this current theme
    testimonialCategory.forEach(element => {
        if (themeSlug === element.node.slug) {
            return (testimonials = element.node.testimonials.edges)
        }
    })

    // If not testimonials, exit
    if (!(testimonials instanceof Array)) {
        return ""
    }

    let testimonialsOne = []
    let testimonialsTwo = []
    if (testimonials.length > 0) {
        testimonials.forEach((element, index) => {
            if (isEven(index)) {
                return testimonialsOne.push(element)
            } else {
                return testimonialsTwo.push(element)
            }
        })
    }

    function isEven(n) {
        return n % 2 === 0
    }

    if (testimonials.length > 0) {
        return (
            <section className="testimonial-wrap">
                <div className="section-header">
                    <span className="section-icon">
                        <img src={quoteImage} alt="Testimonials" />
                    </span>
                    <h3 className="section-title">Testimonials</h3>
                    <p className="section-text">Words from our customers</p>
                </div>

                <div className="testimonial-column">
                    {testimonialsOne.map((testimonial, index) => (
                        <div className="testimonial" key={`cone-` + index}>
                            {ReactHtmlParser(testimonial.node.content)}
                            <cite>{testimonial.node.title}</cite>
                        </div>
                    ))}
                </div>

                {testimonialsTwo.length > 0 && (
                    <div className="testimonial-column">
                        {testimonialsTwo.map((testimonial, index) => (
                            <div className="testimonial" key={`ctwo-` + index}>
                                {ReactHtmlParser(testimonial.node.content)}
                                <cite>{testimonial.node.title}</cite>
                            </div>
                        ))}
                    </div>
                )}
            </section>
        )
    } else {
        return ""
    }
}

export default ThemeTestimonials
