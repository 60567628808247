import React from "react"
import downloadCloudCartImage from "../images/download_from_cloud2.svg"
import voicePresentationImage from "../images/voice_presentation.svg"
import updateRotationImage from "../images/update_left_rotation.svg"
import openBoxImage from "../images/open_box.svg"

const ThemePurchaseInclude = props => {
    const { themeName, themePrice, buyUrl = "" } = props

    return (
        <section className="purchase-includes">
            <div className="purchases-includes-content">
                <div className="section-header">
                    <span className="section-icon">
                        <img
                            src={openBoxImage}
                            alt="So Much More Than a Theme"
                        />
                    </span>
                    <h3 className="section-title">So Much More Than a Theme</h3>
                    <p className="section-text">
                        With this WordPress theme you will also have 1 year
                        included with premium support and unlimited updates.
                    </p>
                </div>
                <div className="purchases-includes-action">
                    <div className="single-theme-price-wrap">
                        <span className="single-theme-price quema-labs-price">
                            <span>$</span>
                            {themePrice}
                        </span>
                        <a
                            href={buyUrl}
                            className="single-theme-buy-button hero-button"
                        >
                            Buy Now
                        </a>
                    </div>
                </div>
            </div>

            <div className="purchase-includes-boxes">
                <div className="service-box">
                    <div className="service-box-icon">
                        <img src={downloadCloudCartImage} alt="Download" />
                    </div>
                    <h5 className="service-box-title">{themeName} Theme</h5>
                </div>

                <div className="service-box">
                    <div className="service-box-icon">
                        <img
                            src={voicePresentationImage}
                            alt="1 Year Premium Support"
                        />
                    </div>
                    <h5 className="service-box-title">
                        1 Year Premium Support
                    </h5>
                </div>

                <div className="service-box">
                    <div className="service-box-icon">
                        <img
                            src={updateRotationImage}
                            alt="1 Year of Updates"
                        />
                    </div>
                    <h5 className="service-box-title">1 Year of Updates</h5>
                </div>
            </div>
        </section>
    )
}

export default ThemePurchaseInclude
