import React from "react"
import voicePresentationImage from "../images/voice_presentation.svg"
import servicesImage from "../images/services.svg"
import searchImage from "../images/search.svg"
import codeImage from "../images/code.svg"

const ThemesInclude = () => (
    <section className="themes-include">
        <div className="themes-include-services">
            <div className="single-theme-service">
                <div className="single-theme-image-wrap">
                    <img
                        src={servicesImage}
                        alt="Solid Framework"
                        className="single-theme-image"
                    />
                </div>
                <div className="single-theme-service-content">
                    <h5 className="single-theme-service-title">
                        Solid Framework
                    </h5>
                    <p className="single-theme-text">
                        You will get the best performance for your site.
                    </p>
                </div>
            </div>
            <div className="single-theme-service">
                <div className="single-theme-image-wrap">
                    <img
                        src={voicePresentationImage}
                        alt="Support & Docs"
                        className="single-theme-image"
                    />
                </div>
                <div className="single-theme-service-content">
                    <h5 className="single-theme-service-title">
                        Support & Docs
                    </h5>
                    <p className="single-theme-text">
                        All themes are well documented and we always are here to
                        help you.
                    </p>
                </div>
            </div>
        </div>

        <div className="themes-include-lines"></div>
        <div className="themes-include-title-wrap">
            <h5 className="themes-include-title">Our Themes include</h5>
        </div>
        <div className="themes-include-lines lines-right"></div>

        <div className="themes-include-services">
            <div className="single-theme-service">
                <div className="single-theme-image-wrap">
                    <img
                        src={codeImage}
                        alt="Latest Technology"
                        className="single-theme-image"
                    />
                </div>
                <div className="single-theme-service-content">
                    <h5 className="single-theme-service-title">
                        Latest Technology
                    </h5>
                    <p className="single-theme-text">
                        HTML5, CSS3 & and the latest WP version always working.
                    </p>
                </div>
            </div>
            <div className="single-theme-service">
                <div className="single-theme-image-wrap">
                    <img
                        src={searchImage}
                        alt="SEO Optimized"
                        className="single-theme-image"
                    />
                </div>
                <div className="single-theme-service-content">
                    <h5 className="single-theme-service-title">
                        SEO Optimized
                    </h5>
                    <p className="single-theme-text">
                        Optimized for search engines so you rank higher.
                    </p>
                </div>
            </div>
        </div>
    </section>
)

export default ThemesInclude
