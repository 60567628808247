import React from "react"
import shoppingCartImage from "../images/shopping_cart.svg"
import multipleDevicesImage from "../images/multiple_devices.svg"
import asteroidImage from "../images/asteroid.svg"
import brushImage from "../images/brush.svg"
import docImage from "../images/doc.svg"
import translationImage from "../images/translation.svg"

const ThemesInclude = props => {
    const { services } = props

    if (!(services instanceof Array)) {
        return ""
    }

    const renderServices = {
        woocommerce: {
            title: "WooCommerce Support",
            content: "Create a shop fast and easy with WooCommerce (free).",
            icon: shoppingCartImage,
        },
        easy_customization: {
            title: "Easy Customization",
            content:
                "Upload your logo, change colors, menus and much more, right from the Admin Panel with no coding skills required.",
            icon: brushImage,
        },
        css_animations: {
            title: "CSS Animations",
            content:
                "Stand out from the crowd with beautiful animations and pixel perfect design.",
            icon: asteroidImage,
        },
        responsive_design: {
            title: "Responsive Design",
            content:
                "Your site looking great on different devices and screen sizes.",
            icon: multipleDevicesImage,
        },
        theme_documentation: {
            title: "Theme Documentation",
            content:
                "Video tutorials and documentation are included to get you up and running in no time.",
            icon: docImage,
        },
        translate_ready: {
            title: "Translate Ready",
            content:
                "Theme is translation and multilingual ready. Use your language without problem.",
            icon: translationImage,
        },
    }

    return (
        <section className="single-theme-services">
            {services.map(service => {
                if (renderServices.hasOwnProperty(service)) {
                    return (
                        <div className="single-theme-service">
                            <div className="single-theme-image-wrap">
                                <img
                                    src={renderServices[service].icon}
                                    alt=""
                                    className="single-theme-image"
                                />
                            </div>
                            <div className="single-theme-service-content">
                                <h5 className="single-theme-service-title">
                                    {renderServices[service].title}
                                </h5>
                                <p className="single-theme-text">
                                    {renderServices[service].content}
                                </p>
                            </div>
                        </div>
                    )
                } else {
                    return ""
                }
            })}
        </section>
    )
}

export default ThemesInclude
